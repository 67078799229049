<template>
  <b-modal
    id="exam_package_modal"
    size="lg"
    centered
    :title="
      editMode ? 'Editar pacote de exames' : 'Criar novo pacote de exames'
    "
  >
    <section>
      <div class="row">
        <div class="col-12">
          <b-form-group class="form-group">
            <label for="name">Nome do Pacote de Exames</label>
            <b-form-input
              autocomplete="off"
              id="name"
              v-model="form.name"
              placeholder="Nome"
              debounce="500"
            />
            <div v-if="validated && !form.name" class="custom-invalid-feedback">
              Um nome para o Pacote é Obrigatório
            </div>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group class="form-group">
            <label for="comment">Observação</label>
            <b-form-input
              autocomplete="off"
              id="comment"
              v-model="form.comment"
              placeholder="Nome"
              debounce="500"
            />
          </b-form-group>
        </div>

      </div>

      <b-row align-v="end">
        <b-col class="col-12">
          <b-form-group class="form-group">
            <label for="exams"> Exames </label>
            <multiselect
              v-if="!customExam"
              v-model="exam"
              id="exams"
              label="value"
              track-by="value"
              :options="examSelectionOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              :showNoResults="true"
              placeholder="Selecione"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult" slot-scope="$props">
                <li @click="setCustomExam(true, $props.search)">
                  <div class="multiselect__option custom-item">
                    <Plus class="icon" />
                    Adicionar solicitação de exame "{{ $props.search }}"
                  </div>
                </li>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
            </multiselect>
            <div class="custom-input" v-else>
              <b-button variant="link" @click="setCustomExam(false)">
                Selecionar um item da lista
              </b-button>
              <input
                autocomplete="off"
                v-model="exam.value"
                :readonly="!canEdit"
                type="text"
                class="form-control"
                placeholder=""
              />
            </div>
            <div
              v-if="validated && form.exams.length < 1"
              class="custom-invalid-feedback"
            >
              Por favor, adicione pelo menos 1 Exame ao Pacote.
            </div>
          </b-form-group>
          <div class="form-group">
            <multiselect
              v-model="exam.examLateralityModel"
              id="laterality"
              :options=lateralityOptions
              :option-height="40"
              :showLabels="false"
              :showNoResults="true"
              placeholder="Selecionar Lateralidade"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
            </multiselect>
          </div>
          <b-form-textarea
              v-model="exam.comment"
              size="lg"
              rows="2"
              placeholder="Insira um comentário"
              no-resize
            />
        </b-col>
        <b-col class="col-9"></b-col>
        <b-col class="col-3">
          <b-button class="btn-add-exam" variant="primary" :disabled="!exam.value" @click="addExam">
            Adicionar Exame
          </b-button>
        </b-col>
      </b-row>

      <b-row class="tableMargin">
        <b-row class="col-12">
          <p class="exams py-2 px-2 col-3">Exames</p>
          <p class="exams py-2 px-2 col-3">Lateralidade</p>
          <p class="exams py-2 px-2 col-3">Comentário</p>
          <p class="exams py-2 px-2 col-3"></p>
        </b-row>
        <b-col class="col-12" v-for="(ex, index) in form.exams" :key="index">
          <b-row class="exam-row p-3">
            <b-col class="col-3">
              {{ ex.value }}
            </b-col>
            <b-col class="col-3">
              {{ ex.examLateralityModel }}
            </b-col>
            <b-col class="col-3">
              {{ ex.comment }}
            </b-col>
            <b-col class="col-3 d-flex justify-content-end">
              <Icon tooltip="Remover Exame" class="text-right">
                <Delete class="icon pointer" @click="removeExam(index)" />
              </Icon>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
    <template #modal-footer>
      <b-button variant="primary" @click="save">
        {{ editMode ? 'Atualizar Pacote' : 'Salvar Pacote' }}
      </b-button>
    </template>
  </b-modal>
</template>
 
<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Icon: () => import('@/components/General/Icon'),
    Plus: () => import('@/assets/icons/plus.svg')
  },
  props: {
    examPackage: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: null,
          clinic_id: null,
          name: null,
          comment: null,
          exams: {}
        }
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      eyeExamsList: state => state.attendance.eyeExams.list
    })
  },

  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      user: JSON.parse(localStorage.getItem('user')),
      validated: false,
      form: {
        id: null,
        clinic_id: null,
        author_id: null,
        name: null,
        comment: null,
        exams: {}
      },
      exam: this.getDefaultExam(),
      customExam: false,
      examSelectionOptions: [],
      lateralityOptions: ['Ambos os Olhos','Olho Esquerdo','Olho Direito']
    }
  },

  async mounted() {
    if (this.eyeExamsList.length === 0) {
      await this.getEyeExamsList().then(res => {
        this.populateLists()
      })
    } else {
      this.populateLists()
    }

    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      const form = Object.assign({}, this.examPackage)

      if (this.editMode) {
        form.exams = form.exams.map(exam => {
          if (exam instanceof Object) {
            return exam
          }
          const examFromList = this.eyeExamsList.find(item => {
            return item.id === exam
          })

          return { id: examFromList.id, value: examFromList.value }
        })
      }

      this.form = form
    })
  },
  methods: {
    ...mapActions('attendance/examPackageRequest', [
      'createExamPackage',
      'updateExamPackage',
      'getExamPackages'
    ]),
    ...mapActions('attendance/eyeExams', ['getEyeExamsList']),
    populateLists() {
      this.eyeExamsList.forEach(item => {
        const options = this.getDefaultExam()
        options.value = item.value
        this.examSelectionOptions.push(options)
      })
    },
    getDefaultForm() {
      return {
        id: null,
        clinic_id: null,
        name: null,
        comment: null,
        exams: []
      }
    },
    getDefaultExam() {
      return {
        custom: false,
        value: null,
        comment: null,
        template: null,
        examLaterality: null,
        examLateralityModel: null,
        updatedTemplate: false
      }
    },
    isValidForm() {
      let isValidForm = true

      if (!this.form.name) {
        isValidForm = false
      }

      if (this.form.exams.length < 1) {
        isValidForm = false
      }

      this.validated = true
      return isValidForm
    },

    addExam() {
      if (this.exam?.value) {
        this.customExam = false
        const value = [...this.form.exams]
        value.push(this.exam)
        this.form.exams.push(Object.assign({}, this.exam))
        this.exam = this.getDefaultExam()
      }
    },
    removeExam(index) {
      this.form.exams = this.form.exams.filter((item, i) => i !== index)
    },

    setCustomExam(value, searchTerm) {
      this.customExam = value
      this.exam.custom = value
      this.exam.value = value ? searchTerm : null
    },

    save() {
      if (!this.isValidForm()) return 
      const isLoading = this.$loading.show()
      try {
        this.form.clinic_id = this.clinic.id
        this.form.author_id = this.user.id
        const data = Object.assign({}, this.form)
        // data.exams = data.exams.map(ex => ex.id)

        if (!this.form.id) {
          this.createExamPackage(data).then(res => {
            this.$toast.success(
              `Novo pacote de exames ${data.name} criado com sucesso`
            )
          })
          this.exam = this.getDefaultExam()
        } else {
          this.updateExamPackage(data).then(res => {
            this.$toast.success(
              `Pacote de exames ${data.name} atualizado com sucesso!`
            )
          })
          this.exam = this.getDefaultExam()
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
        this.validated = false
        this.$bvModal.hide('exam_package_modal')
        this.form = this.getDefaultForm()
      }
    }
  }
}
</script>
<style lang="scss">
.modal-footer {
  border-top: 0 !important;
}
</style>
<style lang="scss" scoped>
.form-group input:read-only {
  background: var(--neutral-100);
}
.multiselect {
  border: 1px solid #c6ceeb;
  border-radius: 8px;
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;

    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
}
.exams {
  background-color: #f4f5fb;
}
.exam-row {
  border-bottom: 1px dashed #c6ceeb;
}
.modal-footer {
  border-top: none;
}
.btn-add-exam {
  margin-top: 1.7rem !important;
}
.btn-add-exam {
  width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
}
.pointer {
  cursor: pointer;
}

.tableMargin {
  margin-top: 10px;
}
</style>
